import React, { useState } from "react";
import * as PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';
import Img from 'gatsby-image';
import Helpers from '../utils/helpers';

import Layout from '../components/layout';
import Breadcrumb from '../components/Breadcrumb';
import ContactForm from "../components/ContactForm";

import "../styles/TopicPage.scss";

const SITE_URL = process.env.GATSBY_DOMAIN;

const propTypes = {
  data: PropTypes.object.isRequired,

};

const TopicTemplate = ({data, location}) => {

  const c = data.contentfulTopic;
  const locale = c.node_locale;

  // Declare a new state variable
  const [subtitleLinkClicked, setSubtitleLinkClicked] = useState(false);

  // Build the URL for this page
  const pageURL = `${SITE_URL}/${locale}/topics/${c.slug}`;

  return (
    <Layout data={data} location={location}>
      <article className="TopicPage Page">
        <section className="container grid-lg">
          <Breadcrumb location={location} locale={locale} />
          <h1>{c.name}</h1>
          {c.summary && (
            <div
              dangerouslySetInnerHTML={{
                __html: c.summary.childMarkdownRemark.html,
              }}
            />
          )}
          {c.relatedCards && (
            <div className="cards">
              {c.relatedCards.map((card, idx) => {
                return card.file !== null ? (
                  <img key={idx} src={card.file.url} />
                ) : (
                  <></>
                );
              })}
            </div>
          )}
        </section>

        {c.subtopics &&
          c.subtopics.map((subtopic, idx) => (
            <section
              id={Helpers.stringToSlug(subtopic.title)}
              className="container grid-lg"
              key={idx}
            >
              <div className="TopicPage__SubtopicTitle">
                <h3 className="">{subtopic.title}</h3>
                <button
                  className="btn btn-link tooltip tooltip-bottom"
                  data-tooltip={
                    subtitleLinkClicked
                      ? "Link copied!"
                      : "Copy a link to this subtopic"
                  }
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    // create our URL to this section
                    const subtitleURL = `${pageURL}#${Helpers.stringToSlug(
                      subtopic.title
                    )}`;

                    // copy this URL to the clipboard
                    navigator.clipboard.writeText(subtitleURL);

                    // update our temporary state
                    setSubtitleLinkClicked(true);
                    setTimeout(() => {
                      setSubtitleLinkClicked(false);
                    }, 2000); 
                  }}
                >
                  <i className="icon icon-link "></i>
                </button>
              </div>
              <div className="TopicPage__Tips cards">
                {subtopic.relatedTips.map((tip, idx) => (
                  <div className={`card color-3-alt`} key={idx}>
                    <Link
                      to={`/${locale}/tips/${tip.slug}`}
                      state={{
                        from: { topic: c.name, 
                                subtopic: subtopic.title,
                                subtopicPathname: Helpers.stringToSlug(subtopic.title),
                                pathname: location.pathname
                              },
                      }}
                    >
                      <h2>{tip.title}</h2>
                      {tip.summary && <p>{tip.summary.summary}</p>}
                    </Link>
                  </div>
                ))}
              </div>
            </section>
          ))}
        {/* {c.relatedTips !== null && (
          <section className="container grid-lg">
            <div className="TopicPage__Tips cards">
              {c.relatedTips.map((tip, idx) => (
                <div className={`card color-3-alt`} key={idx}>
                  <Link
                    to={`/${locale}/tips/${tip.slug}`}
                    state={{
                      from: { title: c.name, pathname: location.pathname },
                    }}
                  >
                    <h2>{tip.title}</h2>
                    <p>{tip.summary.summary}</p>
                  </Link>
                </div>
              ))}
            </div>
          </section>
        )} */}

        <section className="container grid-lg">
          {/* <h5>Popular Terms</h5>
          <div className="LandingPage__terms">
            {c.featuredTerms.map((term, idx) => (
              <Link
                to={`/${locale}/terms/${term.slug}`}
                className="btn mr-2"
                key={idx}
              >
                {term.title}
              </Link>
            ))}
          </div> */}
        </section>
        {/* <ContactForm /> */}
      </article>
    </Layout>
  );
};

TopicTemplate.propTypes = propTypes;

export default TopicTemplate;

export const pageQuery = graphql`
         query topicQuery($id: String!) {
           site {
             siteMetadata {
               languages {
                 defaultLangKey
                 langs
               }
             }
           }
           contentfulTopic(id: { eq: $id }) {
             contentful_id
             name
             node_locale
             slug
             createdAt
             updatedAt
             summary {
               childMarkdownRemark {
                 html
               }
             }
             subtopics {
               title
               relatedTips {
                 title
                 slug
                 summary {
                   summary
                 }
               }
             }
             relatedCards {
               file {
                 url
                 fileName
                 contentType
               }
             }
           }
         }
       `;
