import React from "react";
import Link from "gatsby-link";
import { FormattedMessage } from "react-intl";

import "../styles/Breadcrumb.scss";

const Breadcrumb = ({ location, locale }) => {
    
    let fromTopic;
    if(location && location.state) {
      fromTopic = location.state.from;
    }

    return (
      <ul className="breadcrumb">
        {fromTopic == undefined ? (
          <li className="breadcrumb-item">
            <Link to={`/${locale}/topics`} className="text-color-1">
              <h5 className="text-color-1">
                <FormattedMessage id="allTopics" />
              </h5>
            </Link>
          </li>
        ) : (
          // <li className="breadcrumb-item"></li>
          <>
            <li className="breadcrumb-item">
              <Link to={fromTopic.pathname} className="text-color-1">
                <h5 className="text-color-1">{fromTopic.topic}</h5>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`${fromTopic.pathname}#${fromTopic.subtopicPathname}`} className="text-color-1">
                <h5 className="text-color-3">{fromTopic.subtopic}</h5>
              </Link>
            </li>
          </>
        )}
      </ul>
    );};

export default Breadcrumb;
